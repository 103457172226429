import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: 'b30c21c8-8f86-482c-8da9-8dcbdb7a87bd',
  header: 'Snowchat',
  subheader: 'Snakk SNOMED her!',
  logo: 'semantic_lab_logo_hvit.png',
  theme: {
    header: 'bg-blue-900',
    headerText: 'text-white',
    bubbles: 'bg-green-600'
  },
  chatServer: (process.env.REACT_APP_CHAT_SERVER || 'https://ai.semanticlab.no') + '/api/v1/chatbots/snowchat'
}
export default config
