import React, { useState } from 'react'
import Markdown from 'react-markdown'
import { CardLike, CardWithPopover } from './Card'
import { ExpandablePanel } from './Chat'

export type DocumentViewerProps = {
  documents: any[]
  highlightedDocumentIds: string[]
}
export const SourcesView = (props: DocumentViewerProps) => {
  const [showAll, setShowAll] = useState(true)
  return (
    <>
      {/*<h3 className={'font-bold uppercase text-gray-600'}>Kilder</h3>*/}
      <div className={'flex flex-col gap-2'}>
        {props.documents
          .filter(
            (doc, index) =>
              showAll ||
              props.highlightedDocumentIds.includes('' + doc.documentId),
          ) // Filter before mapping
          .map((doc, index) => (
            <ExpandablePanel
              variant={'quotation'}
              title={
                <div className={''}>
                  <div>{doc.title + ' ' + doc.sourceName}</div>
                  <a
                    href={doc.source}
                    target="_blank"
                    rel="noreferrer"
                    className={'text-sm text-blue-700 no-underline font-normal'}
                  >
                    {doc.source}
                  </a>
                </div>
              }
            >
              <div className={'prose lg:prose prose-stone'}>
                <Markdown>{doc.content}</Markdown>
              </div>
            </ExpandablePanel>
          ))}
        {/*<CardLike onClick={() => setShowAll(!showAll)}>*/}
        {/*  <span className={'text-gray-500 uppercase text-sm'}>*/}
        {/*    {showAll ? 'Skjul øvrige kilder...' : 'Vis øvrige kilder...'}*/}
        {/*  </span>*/}
        {/*</CardLike>*/}
      </div>
    </>
  )
}
