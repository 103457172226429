import React from 'react'

type ButtonProps = {
  onClick: () => void
  children: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'normal'
}

export const Button = ({ onClick, children, size, variant }: ButtonProps) => {
  const extraClasses =
    (size === 'small' ? 'p-1 text-sm' : size === 'large' ? 'p-3' : 'p-2') +
    ' ' +
    (variant == 'primary'
      ? ' bg-black bg-opacity-20 text-white hover:bg-opacity-30 border-black'
      : ' bg-white bg-opacity-80 text-black hover:bg-opacity-90 border-white')

  return (
    <button
      type={'button'}
      onClick={onClick}
      className={'inline-flex gap-2 items-center p-1 rounded-lg border ' + extraClasses}
    >
      {children}
    </button>
  )
}